<template>
  <footer class="footer">
    <div class="footer-inner">
      <div class="container">
        <router-link to="/main" tag="a" class="footer-logo">
          <h1 class="logo"></h1>
        </router-link>
        <ul class="nav">
          <li>
            <button href="#" @click="fnDownloadFile('appg/2025_Appg_Company_Profile.pdf', '앱지(APPG)_회사소개서.pdf')">
              회사소개서
            </button>
          </li>
          <li class="line"></li>
          <li><router-link to="/portfolio" tag="a">포트폴리오</router-link></li>
          <li class="line"></li>
          <li><a @click="openMap">오시는 길</a></li>
          <li class="line"></li>
          <li><router-link to="/estimate" tag="a">견적문의</router-link></li>
        </ul>
        <div class="time-box">
          <p>업무시간<span>09:00 - 18:00</span></p>
          <p>
            점심시간
            <span>12:00 - 13:00</span>
            <span class="sm-txt">(토/일/공휴일 휴무)</span>
          </p>
        </div>
      </div>
      <div class="container">
        <address class="address">
          <p>
            <span>06144&nbsp;</span>서울특별시 강남구 언주로 550, 202호 (청광빌딩, 2층)<br />
            <span class="sm-txt">202, 2F,550, Eonju-ro, Gangnam-gu, Seoul, Republic of Korea</span>
          </p>
          <ul class="contact">
            <li><span>TEL&nbsp;</span><a @click="telTo">1544-6821</a>&nbsp;</li>
            <li><span>FAX&nbsp;</span><a href="tel:070-8611-3033">070-8611-3033</a>&nbsp;</li>
            <li>
              <span>Email&nbsp;</span>
              <a href='mailto:"hyungnam.kim@appg.co.kr"'>hyungnam.kim@appg.co.kr</a>&nbsp;
            </li>
            <li>사업자번호 : 899-87-01282</li>
          </ul>
        </address>
      </div>
    </div>
  </footer>
</template>

<script setup>
import CommonSvc from '@/common/service/CommonSvc';
import Util from '@/common/utils/Util';
import { modalAlertNames } from '@/components/common/globalComponent';
import { useStore } from 'vuex';
import { telTo } from '@/common/utils/suppliment';

const { dispatch } = useStore();

const openMap = () => {
  const payload = {};
  payload.component = modalAlertNames.MAP_AREA_NEW;
  dispatch('layout/pushModalAlertComponent', payload);
};
const fnDownloadFile = async (path, name) => {
  let params = {};
  params.fileName = name;
  params.filePath = path;
  await CommonSvc.downloadFile(params).then(res => {
    if (!Util.isEmpty(name)) {
      const blob = new Blob([res.data], {
        type: res.data.type,
        encoding: 'UTF-8'
      });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = name;
      a.target = '_blank';
      a.click();
    }
  });
};
</script>

<style scoped lang="scss"></style>
<!--
homepage-vue
Created at 2024-03-15
-->
